
import React from 'react';
import './App.css';
//import Navbar from './components/Navbar';
//import Navbar from './components/Navbarelements/index'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
    import Catalogitem from './components/pages/catalogitem';
    function App() {
      return (
    
          <Router>
        
          <Routes>
              <Route path='/:id' element={<Catalogitem/>}/>
          </Routes>
          </Router>
  );
  }
    
  export default App;