//import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
//import TextField from "@material-ui/core/TextField";
//import { Text } from '@react-ui-org/react-ui';
//import LazyLoad from 'react-lazyload';
import '../../Profile.css'
import { Alert, Button, TextInput } from 'evergreen-ui'
import Thanks from "./profilethanks";
import Slider from 'react-slick';
import Modal from 'react-modal';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
  //import { db } from "./firebase";
  import backbutton from '../pages/backbutton.png'
  import weburl from '../pages/kok.png'
  import userimg from '../pages/users.png'
  import blogo from '../pages/blogo.png'
  import ReactStarRatings from 'react-star-ratings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import axios from "axios";
//import { getFirestore, collection, getDocs,firestore,updateDoc,doc,getDoc} from 'firebase/firestore';
const Blog = () => {
    const [businessname, setbusinessname] = useState("");
  const [businessweb, setbusinessweb] = useState("");
  const [textmessage, settextmessage] = useState([]);
  const [businesslogo, setbusinesslogo] = useState("");
  const [businessprofile, setbusinessprofile] = useState("");
  const [businesssignature, setbusinesssignature] = useState("");
  const [translationview,settranslationview]=useState(false)
  const [translationmessage,settranslationmessage]=useState("")
  const[imageload,setimageload]=useState(true)
  const { id } = useParams();
  const [name, setName] = useState('')
  const [phone,setPhone]=useState('')
  const [headertext,setHeadertext]=useState('')
  const [buttontext,setButtontext]=useState('')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const [mailbody,setmailbody]=useState('')
  const [consumermailbody,setconsumermailbody]=useState('')
  const [favoritesmailbody,setfavoritesmailbody]=useState('')
  const [category,setcategory]=useState('')
  const [subcategory,setsubcategory]=useState('')
  const [itemimages,setitemimages]=useState('')
  const [address,setaddress]=useState('')
  const [totalrating,settotalrating]=useState('')
  const [averagerating,setaveragerating]=useState(0)
  const [ratingdata,setratingdata]=useState('')
  const [description,setdescription]=useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);
 const [selectedItem, setSelectedItem] = useState(null);
 const [lang,countrycode ,BID256, ItemID] = id.split('_');
 const [loading, setLoading] = useState(true);

 // Function to handle image load event
 const handleImageLoad = () => {
   setLoading(false);
 };
 // const [offercontentjust,setoffercontentjust]=useState('center')
  const publicurl=process.env.REACT_APP_API_JIFFYBOOKPUBLIC_URL
 
 

 const fetchprofiledata = async () => {
  console.log('incountry',countrycode)
  if(countrycode=='IN'){
    const response=await fetch(`${process.env.REACT_APP_API_GETCATALOGITEMIN_URL}/getcatalogitemdata?parameter=${id}`)
    const result = await response.json();
    setcategory(result.itemdata)
    //setitemimages(result.image)
    setitemimages(Object.values(result.image).slice(1))
    setaddress(result.Address)
    setdescription(result.Description)
   settotalrating(result.itemdata.numTotalRatings)
    setratingdata(result.ratingdata)
    setaveragerating(Number(result.itemdata.numRatingSum/result.itemdata.numTotalRatings))
      console.log('itemimage',result.image)
  }
  if(countrycode=='EE'){
  const response=await fetch(`${process.env.REACT_APP_API_GETCATALOGITEMEE_URL}?parameter=${id}`)
  const result = await response.json();
  setcategory(result.itemdata)
  setsubcategory(result.Subcategories)
  setitemimages(result.image)
  setaddress(result.Address)
  setdescription(result.Description)
    settotalrating(result.itemdata.numTotalRatings)
   setaveragerating(Number(result.itemdata.numRatingSum/result.itemdata.numTotalRatings))
    setratingdata(result.ratingdata)
    console.log('average',itemimages)
  }
  
  handleImageLoad()
 }
useEffect(() => {
  fetchprofiledata()
  }, []);
  
  let symbol;

  switch (category.strBcurrency) {
    case 'EUR':
      symbol = '€'; // Euro symbol
      break;
    case 'INR':
      symbol = '₹'; // Indian Rupee symbol
      break;
    default:
      symbol = '';
  }
const showLoader = () => {
    return (
      <div className="bouncing-ball">
        
      
      <div className="bouncing-ball">
        
        </div>
      </div>
    );
  }
 
  return (
  
    <div className="maindiv">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
       <div >
      {thanksview==false?(
     <div className="content">
        
        <div  style={{ justifyContent: "center", alignItems: "center"}}>
      
        <div  style={{whiteSpace:'nowrap',display:'flex',
        justifyContent: "center", alignItems: "center",fontSize:19,color:'#e75600',fontWeight:'bold',fontFamily:'Lexend Deca',
        marginTop:10}}
        >
       {category.strBcustomitem?category.strBcustomitem:null}
       </div> 
       
       <div style={{display: "flex", justifyContent: "center",fontSize:12, alignItems: "center",fontFamily:'Lexend Deca'}}>
       {category && category[`strBsubitem_${lang}`] ? category[`strBsubitem_${lang}`] : category.strBsubitem}
    </div> 
      
      </div>
     
       {category.numTotalRatings?(
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'5px',marginBottom:'10px'}}>
      {/* Display the average rating using heart icon */}
      <ReactStarRatings
        rating={averagerating}
        starDimension="20px"
        starSpacing="8px"
        starRatedColor="#e75600" // Color of the heart icon
        starHoverColor="#e75600"
        starEmptyColor="lightgray"
        changeRating={() => {}} // Disable rating change
        numberOfStars={5} // Use only one heart icon
        name="rating"
        starRated={<FavoriteIcon fontSize="inherit" />} // Heart icon
      />
       <span style={{marginLeft:'8px',fontSize:14,fontFamily:'Lexend Deca'}}>({category.numTotalRatings?category.numTotalRatings:null})</span>
    </div>):null}
    
    {ratingdata!=''?(
      <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column',marginTop:'5px'}}>
        {ratingdata.map((rating, index) => (
          <div key={index}>
            {/* Display the rating name */}
            <div style={{  textAlign: 'center', marginRight: '10px',fontSize:'12px',marginTop:'10px',
            fontFamily:'Lexend Deca' }}>{rating.id}</div>
            {/* Display the star ratings */}
            <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <ReactStarRatings
              rating={rating.data.numRatingSum / rating.data.numTotalRatings}
              starDimension="10px"
              starSpacing="2px"
              starRatedColor="#e75600"
              starHoverColor="#e75600"
              starEmptyColor="lightgray"
              changeRating={() => {}} // Disable rating change
              numberOfStars={5}
              name={`rating-${index}`}
              
            />
            </div>
          </div>
        ))}
      </div>):null}
      {loading==true?(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'55%'}}>
         <div className="bouncing-ball">
       {showLoader()}
    </div>
    </div>):null}
      {itemimages?(
      <div  >
      {itemimages.map((image, index) => (
        <div key={index}  >
          <img className="itemimages" src={image.strBitemURL} alt={`Image ${index + 1}`} onLoad={handleImageLoad} />
        </div>
      ))}
    </div>):null}
    <div className='description'>
       {category.strBsubitemdesc?category.strBsubitemdesc:null}
       </div> 
       {category.strLTCustText && category.numLT && category.strLTLabel ?(
       <div style={{display: "flex", justifyContent: "center",marginTop:8,
       fontSize:12, alignItems: "center",marginBottom:50,fontFamily:'Lexend Deca'}}>
       {category && category[`strLTCustText_${lang}`] ? category[`strLTCustText_${lang}`] : category.strLTCustText} : {category.numLT} {category && category[`strLTLabel_${lang}`] ? category[`strLTLabel_${lang}`] : category.strLTLabel}
   
       </div> ):null}
       {category.strBcurrency?(
       <footer className="footer"
       >
        {symbol}{category.numBCost}
    </footer>):null}
        </div>):null}
        
     
    </div>
    </div>
  );
};
  
export default Blog;